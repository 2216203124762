<script setup>
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';

const breakpoints = useBreakpoints(breakpointsTailwind);
const mdAndSmaller = breakpoints.smallerOrEqual('md');

const { currentClient: client } = storeToRefs(useClientStore());
</script>
<template>
  <div class="fixed inset-0 flex overflow-hidden">
    <img
      src="/bg_blur.png"
      alt="bg-blur"
      class="w-[80vw] h-[80vw] fixed -left-[2%] -top-[30%] opacity-[0.02] dark:opacity-[0.012]"
    />

    <div class="fixed inset-0 flex overflow-hidden">
      <!-- Primary Sidbar Wrapper -->
      <HPrimarySidebar v-if="!mdAndSmaller" />

      <!-- Center Content Wrapper -->
      <div class="flex flex-1 w-full min-w-0">
        <div class="flex-col items-stretch relative w-full flex-1 flex">
          <!-- Mobile Header -->
          <HMHeader v-if="mdAndSmaller" />

          <!-- Header -->
          <div
            v-else-if="false"
            class="h-[58px] flex-shrink-0 inline-flex px-4 bg-ht-primary-bg-fill-90 border-b border-ht-border-1 justify-between items-center gap-4 md:hidden"
          >
            <div class="justify-start items-center gap-4 inline-flex md:w-full">
              <div class="justify-start items-center gap-2 inline-flex">
                <div
                  class="justify-start items-start gap-1 inline-flex md:hidden"
                >
                  <HButton
                    variant="plain"
                    icon-left="NavArrowLeft"
                    class="w-[34px] h-[34px] px-1"
                    @click="$router.go(-1)"
                  />
                  <HButton
                    variant="plain"
                    icon-left="NavArrowRight"
                    class="w-[34px] h-[34px] px-1"
                    @click="$router.go(1)"
                  />
                </div>

                <span
                  v-if="$route.fullPath.endsWith(`/projects`)"
                  class="grow shrink basis-0 text-ht-monochrome-bw text-sm font-semibold leading-[16.80px] truncate"
                >
                  Projects
                </span>
                <span
                  v-else-if="$route.fullPath.endsWith(`/creatives`)"
                  class="grow shrink basis-0 text-ht-monochrome-bw text-sm font-semibold leading-[16.80px] truncate"
                >
                  Creatives
                </span>

                <HTag
                  v-if="
                    $route.fullPath.endsWith(`/projects`) ||
                    $route.fullPath.endsWith(`/creatives`)
                  "
                  >{{
                    client?._count[
                      $route.fullPath.endsWith(`/projects`)
                        ? 'projects'
                        : 'creatives'
                    ]
                  }}</HTag
                >
              </div>

              <slot name="h-nav" />
            </div>

            <!-- Actions -->
            <div class="min-w-[50%] justify-end items-center gap-4 inline-flex">
              <slot name="h-actions" />
            </div>
          </div>

          <div
            class="flex flex-col items-stretch justify-start overflow-y-auto"
          >
            <slot name="content" />
          </div>

          <!-- Breadcrumbs -->
          <div
            v-if="false"
            class="w-full px-4 py-1.5 bg-ht-primary-bg-fill-90 border-t border-ht-border-1 fixed bottom-0 justify-start items-center gap-1 inline-flex md:hidden"
          >
            <HBreadcrumb>Agency</HBreadcrumb>
            <HBreadcrumb>Client</HBreadcrumb>
            <HBreadcrumb :is-current="true">Projects</HBreadcrumb>
          </div>
        </div>
      </div>
    </div>
    <HConfirmModal />
  </div>
</template>
